import { useContext } from 'react';
import { CurrentOwnerContext } from 'utils/currentOwner';
import OwnerWithDepotMenu from './OwnerWithDepotMenu';
import OwnerWithoutDepotMenu from './OwnerWithoutDepotMenu';

export default function OwnerTypeSpecificMenu() {
  const { currentOwner } = useContext(CurrentOwnerContext);

  return (
    (currentOwner.ownsDepot) ? <OwnerWithDepotMenu /> : <OwnerWithoutDepotMenu />
  );
}
