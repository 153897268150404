import { useCallback, useContext } from 'react';
import { updateBolOwnersAttribute } from 'utils/owners';
import { CurrentOwnerContext } from 'utils/currentOwner';

export function useUpdateOwners() {
  const { currentOwner } = useContext(CurrentOwnerContext);

  return useCallback(() => {
    updateBolOwnersAttribute(currentOwner.name);
  }, [currentOwner]);
}

export function useOwnerInvalidBolPartner() {
  const { currentOwner } = useContext(CurrentOwnerContext);
  return currentOwner.bolPartner === true && currentOwner.bolTokenValid !== true;
}
