import readXlsxFile from 'read-excel-file';
import arrayToSentence from 'array-to-sentence';
import { sentenceCase } from 'change-case';
import Announcement from './announcement';
import GradedAnnouncement from './graded_announcement';

const HEADER_ROW = 3;
const REQUIRED_HEADERS = ['ean', 'asin', 'other reference', 'product title', 'retail price', 'quantity'];
const REQUIRED_HEADERS_FOR_DEPOT_OWNERS = REQUIRED_HEADERS.concat(['sku', 'grade', 'grade remark']);
const DESIRED_HEADERS = [
  'owner reference', 'other reference', 'owner price',
  'return reason', 'customer remark', 'pallet reference',
];

export default class ASNExcelSheet {
  constructor(params) {
    this._file = params.file;
    this.t = params.translateMethod;
    this.withDepot = params.withDepot;
    this.rows = [];
    this.headers = [];
  }

  read() {
    if (!this._file) {
      return new Promise(() => { throw new Error(this.t('classes.ASNExcelSheet.errors.no_file')); });
    }

    return readXlsxFile(this._file).then((rows) => {
      this.rows = rows;
      this.headers = rows[HEADER_ROW].map((header) => header?.toLowerCase()?.replace('*', ''));
      if (!this.#validate()) throw new Error(this.error);
      return rows.slice(HEADER_ROW + 1).map((row, index) => {
        const announcement = (this.withDepot) ? new GradedAnnouncement() : new Announcement();
        row.forEach((value, i) => announcement.setProperty(this.headers[i], value));
        announcement.setProperty('row', index + HEADER_ROW + 1);
        return announcement;
      });
    })
      .catch((err) => {
        throw (err.message) ? err : new Error(this.t('classes.ASNExcelSheet.errors.invalid_file'));
      });
  }

  #validate() {
    if (!this.#validateHeadersPresence()) return false;
    if (!this.#validateRequiredHeaders()) return false;
    if (!this.#validateAnnouncementsPresence()) return false;
    return true;
  }

  #setError(errorId, params = {}) {
    this.error = this.t(`classes.ASNExcelSheet.errors.${errorId}`, params);
  }

  #validateRequiredHeaders() {
    const requiredHeaders = (this.withDepot) ? REQUIRED_HEADERS_FOR_DEPOT_OWNERS : REQUIRED_HEADERS;
    const missingHeaders = requiredHeaders.filter((header) => !this.headers.includes(header));
    const joinedHeaders = arrayToSentence(missingHeaders.map((h) => `'${sentenceCase(h)}'`));
    if (missingHeaders.length > 0) {
      this.#setError('required_headers_missing', {
        count: missingHeaders.length,
        headers: joinedHeaders,
        row: HEADER_ROW + 1,
      });
      return false;
    }
    return true;
  }

  #validateHeadersPresence() {
    if (this.headers.filter((header) => !header).length > 0) {
      this.#setError('header_missing');
      return false;
    }
    return true;
  }

  #validateAnnouncementsPresence() {
    if (!this.rows[HEADER_ROW + 1] || this.rows[HEADER_ROW + 1].join('') === '') {
      this.#setError('no_items', { row: HEADER_ROW + 2 });
      return false;
    }
    return true;
  }

  static get allHeaders() {
    return REQUIRED_HEADERS.concat(DESIRED_HEADERS);
  }

  static get allHeadersWithDepot() {
    return REQUIRED_HEADERS_FOR_DEPOT_OWNERS.concat(DESIRED_HEADERS);
  }
}
